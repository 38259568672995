<template>
  <div class="my-project">
    <div class="container-xl">
      <detailed-project :uuid="uuid" :back-button="true" :buy="false"/>
    </div>
  </div>
</template>

<script>
import { DetailedProject } from "@/components/common";

export default {
  name: "MyProject",
  components: {
    DetailedProject
  },
  data() {
    return {
      uuid: this.$route.params.uuid
    };
  }
}
</script>
